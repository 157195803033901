import * as React from 'react';
import {
  getQaDataAttributes,
  customCssClasses,
  getAriaAttributes,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import { IMenuItemsPropsSDKActions } from '@wix/editor-elements-corvid-utils';
import type {
  IStylableHorizontalMenuImperativeActions,
  IStylableHorizontalMenuProps,
} from '../StylableHorizontalMenu.types';
import semanticClassNames from '../StylableHorizontalMenu.semanticClassNames';
import { MenuItem } from './components/MenuItem/MenuItem';
import { st, classes } from './StylableHorizontalMenu.component.st.css';
import { populateWithChildren } from './utils/populateWithChildren';
import { getHasSubSubs } from './utils/getHasSubSubs';

const StylableHorizontalMenu: React.ForwardRefRenderFunction<
  IStylableHorizontalMenuImperativeActions,
  IStylableHorizontalMenuProps
> = (props, ref) => {
  const {
    id,
    items,
    submenuProps,
    menuWrapper: MenuWrapper,
    className,
    customClassNames = [],
    stylableClassName,
    containerRootClassName,
    menuMode,
    submenuMode,
    isContainerStretched,
    style,
    slots,
    isQaMode,
    fullNameCompType,
    currentUrl,
    onMouseEnter,
    onMouseLeave,
    onItemMouseIn,
    onItemMouseOut,
    onItemClick,
    onItemDblClick,
    reportBiOnMenuItemClick,
    ariaAttributes,
    role,
  } = props;

  // TODO: split styles into ui-types
  const wrapperClassName = st(
    classes.root,
    {
      menuMode,
    },
    stylableClassName,
    containerRootClassName,
    customCssClasses(semanticClassNames.root, ...customClassNames),
  );

  const itemsWithChildren = populateWithChildren(items, slots);

  const onMenuItemClick: IMenuItemsPropsSDKActions['onItemClick'] = (
    ...args
  ) => {
    onItemClick?.(...args);
    reportBiOnMenuItemClick?.(...args);
  };

  return (
    <div
      id={id}
      className={className}
      style={style}
      {...getDataAttributes(props)}
      {...getQaDataAttributes(isQaMode, fullNameCompType)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <MenuWrapper
        ref={ref}
        items={items}
        className={wrapperClassName}
        currentPageHref={currentUrl}
        {...getAriaAttributes({ label: 'Site', ...ariaAttributes, role })}
      >
        {itemsWithChildren.map((item, index) => {
          const mode =
            submenuMode === 'flyout' && !item.children ? 'flyout' : 'column';
          return (
            <MenuItem
              key={index}
              depth={0}
              item={item}
              currentPageHref={currentUrl}
              className={classes.menuItem}
              isContainerStretched={isContainerStretched}
              isColumnStretched={submenuMode === 'columnStretched'}
              submenuProps={submenuProps[mode]!}
              onItemClick={onMenuItemClick}
              onItemMouseIn={onItemMouseIn}
              onItemMouseOut={onItemMouseOut}
              onItemDblClick={onItemDblClick}
              // is needed only in the column mode
              hasColumnSubSubs={mode === 'column' && getHasSubSubs(item)}
            >
              {item.children}
            </MenuItem>
          );
        })}
      </MenuWrapper>
    </div>
  );
};

export default React.forwardRef(StylableHorizontalMenu);
