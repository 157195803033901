import { createContext, useContext, CSSProperties } from 'react';

export const MegaMenuItemContext = createContext<{
  className: string;
  cssVars?: CSSProperties;
}>({
  className: '',
  cssVars: {},
});

export const useMegaMenuItemContext = () => useContext(MegaMenuItemContext);
