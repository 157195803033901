import { withCompController } from '@wix/editor-elements-integrations';
import {
  AnalyticsClicksGroups,
  tryReportAnalyticsClicksBi,
} from '@wix/editor-elements-common-utils';
import {
  IStylableHorizontalMenuProps,
  StylableHorizontalMenuControllerProps,
  StylableHorizontalMenuMapperProps,
  StylableHorizontalMenuStateRefs,
} from '../StylableHorizontalMenu.types';

const compController = withCompController<
  StylableHorizontalMenuMapperProps,
  StylableHorizontalMenuControllerProps,
  IStylableHorizontalMenuProps,
  StylableHorizontalMenuStateRefs
>(({ stateValues, mapperProps }) => {
  const { currentUrl, pageId, reportBi } = stateValues;
  const {
    compId,
    language,
    pagesMap,
    shouldReportAnalyticsElementsClicks,
    ...restMapperProps
  } = mapperProps;

  const reportBiOnMenuItemClick: StylableHorizontalMenuControllerProps['reportBiOnMenuItemClick'] =
    (event, item) => {
      const { label, link } = item ?? {};

      tryReportAnalyticsClicksBi(reportBi, {
        link,
        language,
        shouldReportAnalyticsElementsClicks,
        elementTitle: label,
        pagesMetadata: { pagesMap, pageId },
        element_id: compId ?? event.currentTarget.id,
        elementType: restMapperProps.fullNameCompType,
        elementGroup: AnalyticsClicksGroups.MenuAndSearch,
      });
    };

  return {
    ...restMapperProps,
    currentUrl,
    reportBiOnMenuItemClick,
  };
});

export default compController;
